<template>
  <div class="new-catalog-menu-wrapper bg-white">
    <div class="new-catalog-menu-wrapper__container">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.new-catalog-menu-wrapper {
  border-bottom: 1px solid $body-background-color;
  box-shadow: 0 16px 64px -64px $main-gray-color;

  &__container {
    border-bottom: none;
    display: block;
    height: inherit;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 16px;
  }
}
</style>
