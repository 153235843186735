<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import ContactUs from '~/components/modal-windows/ContactUs.vue'
import MissingProduct from '~/components/modal-windows/MissingProduct.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import type { FooterMenuColumn } from '~/types/footer'

const footerMenuColumns: FooterMenuColumn[] = [
  {
    title: 'Клиентам',
    items: [
      { name: 'Каталог', path: Page.Products },
      { name: 'Оплата заказов', path: Page.Payment },
      { name: 'Способы доставки', path: Page.Delivery }
      // { name: 'Блог', path: `${Page.Articles}?isBlog=yes` }
    ]
  },
  {
    title: 'Услуги',
    items: [
      { name: 'Подбор БАД', path: Page.Tests },
      { name: 'Консультации', path: constants.categories.consultations.path },
      { name: 'Онлайн-курсы', path: constants.categories.learning.path }
    ]
  },
  {
    title: 'О компании',
    items: [
      { name: 'О нас', path: Page.About },
      { name: 'Поставщикам', path: Page.Supplier },
      { name: 'Оптовым клиентам', path: Page.Distributor }
      // { name: 'Специалистам', path: Page.Partner }
      // { name: 'Мы в офлайне', path: Page.Offline }
    ]
  },
  {
    title: 'Поддержка',
    items: [
      { name: 'Контакты', path: Page.Contacts },
      { name: 'Частые вопросы', path: Page.Faq },
      { name: 'Возврат продукции', path: Page.Return }
    ]
  }
]

const collapsedFooterMenuColumns = ref(new Set(footerMenuColumns.map((x: FooterMenuColumn): string => x.title)))

function isCollapsed (title: string): boolean {
  return collapsedFooterMenuColumns.value.has(title)
}

async function openContactUsModalWindow (attrs = {}): Promise<void> {
  await useModal({ component: ContactUs, attrs }).open()
}

async function openMissingProductModalWindow (): Promise<void> {
  await useModal({ component: MissingProduct, attrs: { textPrefix: 'Недостающий товар' } }).open()
}

function toggleCollapse (title: string): void {
  if (isCollapsed(title)) {
    collapsedFooterMenuColumns.value.delete(title)
  } else {
    collapsedFooterMenuColumns.value.add(title)
  }
}
</script>

<template>
  <div class="flex flex-col md:flex-row">
    <div class="mb-3 md:mb-0 md:w-1/5">
      <Logo class="md:mb-3" />
      <div class="hidden text-sm text-[#a0a29f] md:block">
        ООО «Ай&nbsp;ТАБ», 2020—2024.<br>
        Все права защищены
      </div>
    </div>
    <div class="mb-6 flex flex-col md:mb-0 md:w-3/5 md:flex-row">
      <div
        v-for="{ items, title } in footerMenuColumns"
        :key="title"
        class="md:w-1/4"
      >
        <div
          class="
            flex items-center justify-between border-b border-solid border-[#eee] py-2
            md:pointer-events-none md:block md:justify-normal md:border-none md:py-0
          "
          @click="toggleCollapse(title)"
        >
          <div class="text-sm font-medium md:text-base">
            {{ title }}
          </div>
          <div class="h-6 md:hidden">
            <SvgIcon
              class="text-[24px] text-main-green"
              :name="isCollapsed(title) ? 'outlined/expand-more' : 'outlined/expand-less'"
            />
          </div>
        </div>
        <div
          class="mt-3 flex flex-col space-y-3 pb-2 md:mt-4 md:pb-0"
          :class="{ 'hidden md:flex': isCollapsed(title) }"
        >
          <NuxtLink
            v-for="{ name, path } in items"
            :key="name"
            class="text-sm text-[#7b7b7b] hover:text-main-green hover:no-underline md:text-base"
            :to="path"
          >
            {{ name }}
          </NuxtLink>
        </div>
      </div>
    </div>
    <div class="mb-5 md:mb-0 md:w-1/5">
      <div class="mb-3 flex flex-row items-center md:flex-col md:items-start">
        <div class="mr-4 md:mb-[10px] md:mr-0">
          <a
            class="text-[17px] font-medium leading-none text-inherit hover:text-main-green hover:no-underline md:text-[28px]"
            :href="constants.phoneNumbers.support.href"
          >{{ constants.phoneNumbers.support.formattedText }}</a>
        </div>
        <div class="text-sm text-[#a0a29f] md:text-base">
          Пн. —&nbsp;пт.: c&nbsp;10 до&nbsp;19 (МСК)<br>
          Сб. —&nbsp;вс.: c&nbsp;10 до&nbsp;16 (МСК)
        </div>
      </div>
      <div class="mb-3">
        <a
          class="cursor-pointer text-sm text-[#7b7b7b] hover:text-main-green hover:no-underline md:text-base"
          href="#"
          @click.prevent="openContactUsModalWindow"
        >Написать нам</a>
      </div>
      <div>
        <a
          class="cursor-pointer text-sm text-[#7b7b7b] hover:text-main-green hover:no-underline md:text-base"
          href="#"
          @click.prevent="openMissingProductModalWindow"
        >Не&nbsp;нашли нужный товар?</a>
      </div>
    </div>
  </div>
</template>
