<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import SearchInputInHeader from '~/components/form-elements/SearchInputInHeader.vue'
import HeaderExtraMenu from '~/components/menus/HeaderExtraMenu.vue'
import HeaderMainMenu from '~/components/menus/HeaderMainMenu.vue'
import NewCatalogMenu from '~/components/menus/NewCatalogMenu.vue'
import Auth from '~/components/modal-windows/Auth.vue'
import NewCatalogMenuOpenButton from '~/components/new-catalog-menu/NewCatalogMenuOpenButton.vue'
import AuthAction from '~/enums/auth-action'
import Page from '~/enums/page'
import { useAuthStore } from '~/store/auth'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const isMobileMenuOpen = ref(false)
const isSearchMenuOpen = ref(false)
const query = ref(null)
const showCatalogMenu = ref(false)
const showCatalogMenuButton = ref(false)

watch(
  () => route.path,
  (): void => {
    if (isSearchMenuOpen) {
      toggleSearchMenu(false)
    }

    showCatalogMenu.value = !showCatalogMenu.value
  }
)

onMounted((): void => {
  if (route.query.enableCatalogMenu === 'yes') {
    showCatalogMenuButton.value = true
  }
})

async function goToProductsPage (): Promise<void> {
  if (query.value) {
    toggleSearchMenu(false)

    await open(`${Page.Products}?query=${encodeURIComponent(query.value)}`)
  }
}

async function open (path: string, isAuthRequired = false): Promise<void> {
  if (isMobileMenuOpen) {
    toggleMobileMenu(false)
  }

  if (isAuthRequired && !authStore.isUser) {
    await useModal({
      component: Auth,
      attrs: { action: AuthAction.SignIn, needToRedirectToAccountPage: true }
    }).open()
  } else {
    await router.push(path)
  }
}

function toggleMobileMenu (x: boolean): void {
  const className = 'has-mobile-menu'

  isMobileMenuOpen.value = x

  if (x) {
    document.body.classList.add(className)
    isSearchMenuOpen.value = false
  } else {
    document.body.classList.remove(className)
  }
}

function toggleSearchMenu (x: boolean): void {
  isSearchMenuOpen.value = x

  if (x) {
    // nextTick(() => emit('onSearchInputInHeaderFocus'))
    isMobileMenuOpen.value = false
  } else {
    // query.value = null
  }
}

function hideMobileAndSearchMenu (): void {
  isMobileMenuOpen.value = false
  isSearchMenuOpen.value = false
}
</script>

<template>
  <header class="bg-white">
    <div class="container flex items-center justify-between">
      <HeaderExtraMenu
        v-show="!isSearchMenuOpen"
        class="left"
        is-mobile
        :is-mobile-menu-open="isMobileMenuOpen"
        :is-search-menu-open="isSearchMenuOpen"
        @open="open"
        @toggle-mobile-menu="toggleMobileMenu"
      />

      <div class="logo-area" :class="{ 'with-search-menu': isSearchMenuOpen }">
        <Logo @click="hideMobileAndSearchMenu" />
      </div>

      <NewCatalogMenuOpenButton
        v-if="showCatalogMenuButton"
        icon-name="outlined/grid-view"
        title="Каталог"
        @click="showCatalogMenu = !showCatalogMenu"
      />

      <Transition>
        <NewCatalogMenu v-if="showCatalogMenuButton" v-show="showCatalogMenu" @click.self="showCatalogMenu = !showCatalogMenu" />
      </Transition>

      <HeaderMainMenu v-show="!isSearchMenuOpen" @open="open" />

      <SearchInputInHeader
        v-model="query"
        class="search-input"
        :class="{ 'is-expanded': isSearchMenuOpen }"
        placeholder="Название, симптом, система организма, активное вещество"
        @on-focus="isSearchMenuOpen = true"
        @on-keyup-enter="goToProductsPage"
        @on-search-button-click="goToProductsPage"
      />

      <SvgIcon
        v-show="isSearchMenuOpen"
        class="close-search-menu-button cursor-pointer"
        name="outlined/close"
        @click="toggleSearchMenu(false)"
      />

      <HeaderExtraMenu v-show="!isSearchMenuOpen" @open="open" />

      <HeaderExtraMenu
        v-show="!isSearchMenuOpen"
        is-mobile
        position="right"
        @open="open"
        @toggle-search-menu="toggleSearchMenu"
      />
    </div>

    <div v-show="isMobileMenuOpen" class="mobile-menu bg-white">
      <HeaderMainMenu is-mobile @open="open" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

header {
  box-sizing: border-box;
  left: 0;
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
  transition: all .5s ease-in-out;
  width: 100%;
  z-index: 999;

  .container {
    border-bottom: 1px solid $body-background-color;
    height: 60px;
  }

  .left,
  .right {
    flex-grow: 1;
    flex-basis: 0;
  }

  .search-input {
    margin-left: 24px;
    width: 100%;

    @media screen and (max-width: 1070px) {
      &:not(.is-expanded) {
        display: none;
      }

      margin-left: 0;
    }
  }

  .close-search-menu-button {
    font-size: 24px;
    margin-left: 16px;

    &:hover {
      color: $main-red-color;
    }
  }

  .logo-area.with-search-menu {
    @media screen and (max-width: 1070px) {
      display: none;
    }
  }

  .mobile-menu {
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1001;

    @media screen and (max-width: 1070px) {
      display: block;
    }
  }
}
</style>
