<script lang="ts" setup>
import constants from '~/constants'

const needToShowInfoAboutCookies = ref(false)

onMounted(() => {
  needToShowInfoAboutCookies.value = !areCookiesAccepted()
})

function acceptCookies (): void {
  localStorage.setItem('areCookiesAccepted', JSON.stringify(true))
}

function areCookiesAccepted (): boolean {
  const areCookiesAccepted = localStorage.getItem('areCookiesAccepted') as string | null

  // TODO: For backward compatibility. Remove it.
  if (areCookiesAccepted === '1') {
    acceptCookies()

    return true
  }

  if (areCookiesAccepted === null) {
    return false
  }

  return JSON.parse(areCookiesAccepted)
}

function close (): void {
  needToShowInfoAboutCookies.value = false

  acceptCookies()
}
</script>

<template>
  <div v-if="needToShowInfoAboutCookies" class="cookies sticky flex w-full items-center justify-center">
    <p class="cookies__info text-white">
      Оставаясь на&nbsp;нашем сайте, вы&nbsp;соглашаетесь с&nbsp;использованием файлов cookie. <a class="text-white underline" :href="constants.documentUrls.dataProtectionPolicy" target="_blank">Подробнее</a>.
    </p>
    <SvgIcon class="cookies__close-button cursor-pointer text-white" name="outlined/close" @click="close" />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.cookies {
  background: $main-gray-color;
  bottom: 0;
  z-index: 12;

  @media screen and (max-width: 600px) {
    bottom: 50px;
  }

  &__close-button {
    font-size: 24px;
    position: absolute;
    right: 12px;

    &:hover {
      color: $main-red-color;
    }
  }

  &__info {
    padding: 24px 48px 24px 12px;
  }
}
</style>
