<script lang="ts" setup>
import { ModalsContainer, useModal } from 'vue-final-modal'

import BottomNavigationMenu from '~/components/menus/BottomNavigationMenu.vue'
import BackInStockNotification from '~/components/modal-windows/BackInStockNotification.vue'
// import { useBirthday4ModalWindow } from '~/composables/birthday-4-modal-window'
import { useHealthAspectsModalWindow } from '~/composables/health-aspects-modal-window'
import { useProduct } from '~/composables/product'
import constants from '~/constants'
import helpers from '~/helpers'
import JsonApiHelper from '~/helpers/json-api-helper'
import RetailRocketApiService from '~/services/retail-rocket-api-service'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'
import { useSpecialistsStore } from '~/store/specialists'
import { useUserStore } from '~/store/user'

const { $apiHelper } = useNuxtApp()
const route = useRoute()
const router = useRouter()
const { addProductToCart, setCartProductQuantity } = useProduct()
const authStore = useAuthStore()
// const { showBirthday4ModalWindowIfNeeded } = useBirthday4ModalWindow()
const { showHealthAspectsModalWindowIfNeeded } = useHealthAspectsModalWindow()
const productsStore = useProductsStore()
const specialistsStore = useSpecialistsStore()
const userStore = useUserStore()

router.afterEach(() => {
  if ((window as any).PopMechanic && (window as any).PopMechanic.update) {
    (window as any).PopMechanic.update()
  }
})

onMounted(async (): Promise<void> => {
  await parseUtmParameters()

  initializeRetailRocket()

  initializeSearchBooster()

  initializeVkOpenApi()

  showHealthAspectsModalWindowIfNeeded()

  // await showBirthday4ModalWindowIfNeeded()
})

useAsyncData(async (): Promise<void> => {
  initializeStores()

  await updateCartAndWishListTotalItems()
})

useHead(() => {
  const searchBoosterApiKey = route.query.testSearchBooster === 'yes'
    ? '73e15ed9-9913-48e2-84b9-425a19bbc404'
    : 'b5ad06e9-ea52-4e5a-a575-1b2961e673f2'

  return {
    link: [
      { rel: 'canonical', href: `${constants.siteUrl}${route.path}` }
    ],
    script: [
      {
        innerHTML: 'var sbRegionId = \'\';\n'
          + 'var sbUserId = \'\';\n'
          + 'var sbSegmentId = \'\';\n'
          + 'var sbScript = document.createElement(\'script\');\n'
          + `sbScript.src = 'https://cdn2.searchbooster.net/scripts/widget/${searchBoosterApiKey}/main.js';\n`
          + 'sbScript.setAttribute(\'data-sb-init-script\', 1);\n'
          + 'sbScript.async = true;\n'
          + 'document.body.appendChild(sbScript);',
        tagPosition: 'bodyClose',
        type: 'text/javascript'
      }
    ]
  }
})

function getProductIdAsString (productId: number | string): string {
  return typeof productId === 'string' ? productId : String(productId)
}

function initializeRetailRocket (): void {
  (window as any).addToCart = (productId: number | string, quantity = 1): Promise<void> =>
    addProductToCart(getProductIdAsString(productId), quantity, true);
  (window as any).onSearchButtonClick = (query: string): void => RetailRocketApiService.search(query)
}

function initializeSearchBooster (): void {
  (window as any).sbLayer = (window as any).sbLayer || {};
  (window as any).sbLayer.addProductToCart = (productId: number | string, quantity = 1): Promise<void> =>
    addProductToCart(getProductIdAsString(productId), quantity);
  (window as any).sbLayer.getCartProductQuantity = (productId: number | string): number | null =>
    productsStore.getCartProductQuantity(getProductIdAsString(productId));
  (window as any).sbLayer.onNotifyMeButtonClick = (productId: number | string): Promise<void> =>
    openBackInStockNotificationModalWindow(getProductIdAsString(productId));
  (window as any).sbLayer.setCartProductQuantity = (productId: number | string, quantity = 1): Promise<void> =>
    setCartProductQuantity(getProductIdAsString(productId), quantity)
}

function initializeStores (): void {
  authStore.initializeStore({
    guestId: useCookie('guestId').value || null,
    token: useCookie('token').value || null,
    userId: useCookie('userId').value || null
  })
  userStore.initializeStore()
}

function initializeVkOpenApi (): void {
  (window as any).vkAsyncInit = function () {
    (window as any).VK.Widgets.CommunityMessages(
      'vk_community_messages',
      208946896,
      { disableExpandChatSound: 1, disableTitleChange: 1, tooltipButtonText: 'Спросите нас' }
    )
  }

  setTimeout(
    function (): void {
      const scriptElement = document.createElement('script')
      scriptElement.async = true
      scriptElement.src = 'https://vk.com/js/api/openapi.js?169'
      document.getElementById('vk_api_transport')?.appendChild(scriptElement)
    },
    11000 // For Google PageSpeed.
  )
}

async function openBackInStockNotificationModalWindow (productId: string): Promise<void> {
  await useModal({ component: BackInStockNotification, attrs: { productId } }).open()
}

async function parseUtmParameters (): Promise<void> {
  const query = { ...route.query }
  const {
    admitad_uid: admitadUid,
    click_id: cityAdsClickId,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
    utm_medium: utmMedium,
    utm_source: utmSource,
    utm_term: utmTerm
  } = query

  let needToReplaceQuery = false

  const paramsToDelete = [
    'admitad_uid',
    'click_id',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term'
  ]

  for (const x of paramsToDelete) {
    if (Object.prototype.hasOwnProperty.call(query, x)) {
      delete query[x]

      if (!needToReplaceQuery) {
        needToReplaceQuery = true
      }
    }
  }

  if (needToReplaceQuery) {
    await router.replace({ query })
  }

  const utmParameters = helpers.purifyUtmParameters({ utmCampaign, utmContent, utmMedium, utmSource, utmTerm })

  if (utmParameters !== null && constants.paidUtmSources.includes(utmSource as string)) {
    localStorage.setItem('utmParameters', JSON.stringify(utmParameters))
  }

  if (utmSource === 'admitad') {
    if (typeof admitadUid === 'string' && admitadUid !== '') {
      const cookieOptions = { maxAge: 7776000, path: '/' } // 7776000 seconds is 90 days.
      useCookie('admitad_uid', cookieOptions).value = admitadUid
    }

    const cityAdsClickId = useCookie('cityads_click_id')

    if (cityAdsClickId.value) {
      cityAdsClickId.value = null
    }
  } else if (utmSource === 'cityads') {
    if (typeof cityAdsClickId === 'string' && cityAdsClickId !== '') {
      const cookieOptions = { maxAge: 2592000, path: '/' } // 2592000 seconds is 30 days.
      useCookie('cityads_click_id', cookieOptions).value = cityAdsClickId
    }

    const admitadUidCookie = useCookie('admitad_uid')

    if (admitadUidCookie.value) {
      admitadUidCookie.value = null
    }
  } else if (constants.paidUtmSources.includes(utmSource as string)) {
    const admitadUidCookie = useCookie('admitad_uid')

    if (admitadUidCookie.value) {
      admitadUidCookie.value = null
    }

    const cityAdsClickId = useCookie('cityads_click_id')

    if (cityAdsClickId.value) {
      cityAdsClickId.value = null
    }
  }
}

async function updateCartAndWishListTotalItems (): Promise<void> {
  if (!authStore.isGuest && !authStore.isUser) {
    return
  }

  const params = { include: 'cartProducts' }

  try {
    const userOrGuestResponse = authStore.isUser
      ? await $apiHelper.users.getUser(authStore.userId!, params)
      : await $apiHelper.guests.getGuest(params)
    const { data: userOrGuestData } = JsonApiHelper.denormalizeResponse(userOrGuestResponse) as any

    if (authStore.isUser && userOrGuestData) {
      userStore.updateFromUserData(userOrGuestData)
    }

    if (userOrGuestData && userOrGuestData.meta) {
      const { cartTotalItems, wishListTotalItems } = userOrGuestData.meta

      if (Number.isInteger(cartTotalItems) && Number.isInteger(wishListTotalItems)) {
        productsStore.setCartTotalItems(cartTotalItems)
        productsStore.setWishListTotalItems(wishListTotalItems)
      }

      const cartProducts = userOrGuestData.relationships.cartProducts.data

      for (const { id, meta } of cartProducts) {
        productsStore.addCartProduct({ id, quantity: meta.quantity })
      }
    }
  } catch (error) {
    console.error(error)
  }
}
</script>

<template>
  <div>
    <div id="vk_api_transport" />

    <Header />

    <main>
      <slot />
    </main>

    <Footer />

    <BottomNavigationMenu />

    <div id="vk_community_messages" />

    <Cookies />

    <ModalsContainer />

    <img
      alt=""
      height="0"
      onerror="var s=document.createElement('script');s.src=this.src+'?iserror',s.async=!0,document.body.appendChild(s);"
      src="//p.cityadstrack.com/analytics.gif"
      width="0"
    >
  </div>
</template>

<style lang="scss" scoped>
#vk_community_messages {
  z-index: 11 !important;

  @media screen and (max-width: 600px) {
    margin-bottom: 70px !important;
  }
}
</style>
